<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadoclientes') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                <clientes-estados />
                </div>
                <div class="col-md-10">
                <tabla-datos :columnas="columnas" :datos="clientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerClientes($event)">
                </tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import ClientesEstados from './listado/ClientesEstados.vue';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import { FilterMatchMode } from 'primevue/api';
export default {
    components: {
        'tabla-datos': TablaDatos,
        'clientes-estados': ClientesEstados,
    },
    props: {
        id: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            total: 25,
            clientes: [],
            eliminado: false,
            parametros: '', 
            filtrosBuscador: ['nombre'], 
            filtros: { 
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Poblacion',
                    field: 'poblacion',                
                },
                {
                    header: 'Teléfono',
                    field: 'telefono1',
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idcliente',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar cliente ' + id);
                                this.$router.push({
                                    name: 'Cliente',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idcliente',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar cliente ' + id);
                                if (confirm('Desea eliminar al cliente')) {
                                    this.eliminar(id);
                                    this.eliminado = true;
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerClientes(parametros) {
            /* get  modulos/pwgsapi/index.php/clientes/:id */
            const api = new PwgsApi();
            const params = parametros;
            this.$store.dispatch('cambiarCargando', true);
            const datos = await api.get('clientes', params);
            this.clientes = datos.datos;
            console.log('clientes',this.clientes);
            this.total = datos.n_total_registros;
            this.eliminado = false;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.ciudad");
            this.columnas[2].header = this.$t("general.telefono");
            this.columnas[2].header = this.$t("general.acciones");
            this.$store.dispatch('cambiarCargando', false);
        },
        async eliminar(id) {
            /* delete  modulos/pwgsapi/index.php/clientes/:id */
            const api = new PwgsApi;
            await api.delete('clientes/' + id);
            this.obtenerClientes();
            },
    },
    watch: {
        eliminado() {
            this.obtenerClientes();
        },
        id() {
            this.obtenerClientes();
        },
    },
    mounted() {
        this.eliminado = false;
        this.obtenerClientes();
    },
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>